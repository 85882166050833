import { CoPresent } from "@mui/icons-material";
import React, { useState } from "react";

// Components
import ActionBtn from "../Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./TabView.module.scss";

const TabView = ({ menuConfig, tabViews, activeBtnColor }) => {
    const [navigation] = useState(menuConfig);
    const [menuState, setMenuState] = useState(menuConfig[0].id);
    const [tabs] = useState(tabViews);

    const changeTabHandler = (event) => setMenuState(event.currentTarget.id);
    return (
        <article>
            <nav className={styles.nav_btns}>
                {navigation &&
                    navigation.map((input) => {
                        let btnActive = input.id === menuState ? activeBtnColor : "";
                        return (
                            <ActionBtn
                                id={input.id}
                                key={input.id}
                                btnType={input.btnType}
                                btnStyle={input.disabled ? styles.btnDisable : styles.btn}
                                activeBtnStyle={btnActive}
                                btnText={input.btnText}
                                textStyle={input.disabled ? styles.btn_textDisable : (input.textStyle || styles.btn_text)}
                                onClick={changeTabHandler}
                                disabled={input.disabled ? true : false}
                                notificationAlert={input.notificationAlert}
                            />
                        );
                    })}
            </nav>
            {tabs &&
                tabs.map((input, index) => {
                    if (input.id === menuState && (!menuConfig[index].disabled || menuConfig[index].disabled == undefined))
                        return <section key={index}>{input.view}</section>;
                })}
        </article>
    );
};

export default TabView;
