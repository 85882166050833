import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { TeacherContext } from "../../context/teacher-context";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import CreateEvaluation from "../../../src/components/Tabs/Teacher/CreateEvaluation/CreateEvaluation";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

// Icons
import evaluationIcon from "../../assets/images/exam.svg";

// Styles
import styles from "./Evaluation.module.scss";

const Evaluation = () => {
  // Authentication context
  const auth = useContext(AuthContext);

  // Teacher App context
  const context = useContext(TeacherContext);

  // Backend Request Hook
  const { isLoading, error, okHttp, sendRequest } = useHttpRequest();

  // Teacher Data State
  const [teacherInfos, setTeacherInfos] = useState();

  // Assignments Data State
  const [subjects, setSubjects] = useState();

  // Assignments Data State
  const [groups, setGroups] = useState();

  // Assignments Data State
  const [report, setReport] = useState();

  const [isError, setIsError] = useState(false);

  const [httpError, setHttpError] = useState(false);

  // Navigation
  const [evaluationNav] = useState([
    {
      id: "group",
      btnText: "Matières/Groupes",
      btnType: "text",
    },
  ]);

  // Fonction qui permet de restructurer le tableau de matières

  const assignementConstructor = async (array) => {
    let newArray = [];
    for (const key in array) {
      array[key].label = key;
      newArray.push(array[key]);
    }
    return newArray;
  };

  // ForEach qui permet de remplir les tableaux des matières et des groupes pour les prochains FETCH

  const filtredForGetGroupAndSubject = async (array) => {
    let subjectArrayForUrl = [];
    let groupArrayForUrl = [];

    if (array.length == 0) {
      setIsError(true);
    }

    array.forEach((element) => {
      subjectArrayForUrl.push(element.subject);
      if (element.group_id !== "0") {
        groupArrayForUrl.push(element.group_id);
      }
    });
    return {
      subjectArrayForUrl: subjectArrayForUrl,
      groupArrayForUrl: groupArrayForUrl,
    };
  };

  // Fetch groups
  useEffect(() => {
    const fetchData = async () => {
      // FETCH FOR TEACHER

      const teacherUrl = `${process.env.REACT_APP_API_HOST}/evaluation/teacher/v1/${context.teacherSelectedAccount}`;
      try {
        const teacherFetch = await sendRequest(teacherUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setTeacherInfos({
          teacher_id: context.teacherSelectedAccount,
          groups_ids: teacherFetch[0].groups_id,
          place_id: teacherFetch[0].place_id,
          vcard: context.teacherVcard,
          currentYear: context.teacherCurrentAccountYear,
        });

        //FETCH FOR REPORT

        const reportUrl = `${process.env.REACT_APP_API_HOST}/evaluation/get-report?teacher_id=${context.teacherVcard}&teacherCurrentYear=${context.teacherCurrentAccountYear}`;
        const reportFetch = await sendRequest(reportUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });

        // Filtre les bulletins par rapport à l'année

        const filtredReportByYear = reportFetch.filter(
          (element) => element.school_year === context.teacherCurrentAccountYear
        );
        setReport(filtredReportByYear);

        const newArray = await filtredForGetGroupAndSubject(
          filtredReportByYear
        );

        // FETCH FOR SUBJECTS

        const subjectsUrl = `${process.env.REACT_APP_API_HOST}/evaluation/subject/v1?subject_id=${newArray.subjectArrayForUrl}`;

        const subjectsFetch = await sendRequest(subjectsUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        const newSubjectsArray = await assignementConstructor(
          subjectsFetch.subjects
        );
        setSubjects(newSubjectsArray);

        // FETCH GROUPS
        if (newArray.groupArrayForUrl.length !== 0) {
          const ids = newArray.groupArrayForUrl;
          const groupsUrl = `${process.env.REACT_APP_API_HOST}/evaluation/get-group?ids=${ids}&school_year=${context.teacherCurrentAccountYear}`;
          const groupsFetch = await sendRequest(groupsUrl, "GET", null, {
            Authorization: "Bearer " + auth.token,
          });
          setGroups(groupsFetch);
        }
      } catch (err) {
        setHttpError(true)
        console.error(err);
      }
    };
    fetchData();
  }, []);

  let tabViews;
  if (report && subjects) {
    tabViews = [
      {
        id: "group",
        view: (
          <CreateEvaluation
            teacherInfos={teacherInfos}
            report={report}
            subjects={subjects}
            groups={groups}
          />
        ),
      },
    ];
  }

  return (
    <main className={styles.container}>
      {isLoading && (
        <div className="spinner">
          <LoadingSpinner textColor={styles.spinner_text_color} />
        </div>
      )}
      <HeaderBanner
        title="Evaluation"
        icon={evaluationIcon}
        iconColor={styles.icon_color}
        bgColor={styles.banner_color}
      />
      {!isLoading && groups && (
        <>
          <TabView
            menuConfig={evaluationNav}
            tabViews={tabViews}
            activeBtnColor={styles.btn_active}
            className={styles.tabViewsEval}
          />
        </>
      )}
      {isError && (
        <div className={styles.noReport}>
          <p className={styles.pError}>
            Vous n'avez pas encore de bulletins générés pour cette année
            scolaire.
          </p>
        </div>
      )}
      {httpError && (
        <div className={styles.noReport}>
          <p className={styles.pError}>
            Une erreur est apparue. Veuillez réessayer ultèrieurement.
          </p>
        </div>
      )}
    </main>
  );
};

export default Evaluation;
