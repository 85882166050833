/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from "react";

import Modal from "../../../Modal/Modal";
import GenerateEvaluation from "../GenerateEvaluation/GenerateEvaluation";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../context/auth-context";
import { TeacherContext } from "../../../../context/teacher-context";
import SearchIcon from "../../../../assets/images/search-solid.svg";

import styles from "./CreateEvaluation.module.scss";
import ReportLine from "../EvaluationTabs/ReportBlock/ReportBlock";

const CreateEvaluation = (props) => {
  // Actual Modal View in use

  const auth = useContext(AuthContext);

  const [modalView, setModalView] = useState();

  const [modalStatus, setModalStatus] = useState(false);

  const [isModalClicked, setModalClick] = useState(false);

  const { sendRequest } = useHttpRequest();

  const context = useContext(TeacherContext);

  const [currentReport, setCurrentReport] = useState();

  const [currentNotes, setCurrentNotes] = useState();

  const [report] = useState(props.report);

  const [notes, setNotes] = useState();

  const [subjects] = useState(props.subjects);

  const [teacherInfos] = useState(props.teacherInfos);

  const [groups] = useState(props.groups);

  const [category] = useState([
    {
      default: "",
      label: "",
    },
    {
      default: "Contrôle Continu",
      label: "controle_continu",
    },
    {
      default: "Étude de Cas",
      label: "case_study",
    },
    {
      default: "Exercice",
      label: "exercise",
    },
    {
      default: "Projet",
      label: "project",
    },
    {
      default: "Projet de Groupe",
      label: "group_project",
    },
    {
      default: "Travail de Groupe",
      label: "group_work",
    },
    {
      default: "Test Final",
      label: "final_test",
    },
    {
      default: "Présentation Orale",
      label: "oral_presentation",
    },
    {
      default: "Participation",
      label: "participation",
    },
    {
      default: "GEMA LAB",
      label: "gema_lab",
    },
  ]);

  const fixScrollPosition = (status) => {
    if (status === false) {
      const scrollPosition = `${window.scrollY}px`;
      const body = document.body;
      body.style.position = "fixed";
      body.style.top = `-${scrollPosition}`;
    } else {
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  };

  // Fonction pour trouver le label de la matière en fonction du nom de celle-ci dans le bulletin
  for (const keysReport of report) {
    for (const keysSubject of subjects) {
      if (keysReport.subject === keysSubject.label) {
        keysReport.subject_Name = keysSubject.default;
      }
    }
  }

  // Fonction pour trouver le nom du groupe en fonction de l'id du groupe dans l'objet report
  if (groups) {
    for (const keysReport of report) {
      for (const keysGroup of groups) {
        if (keysReport.group_id == keysGroup.id) {
          keysReport.group_name = keysGroup.name;
        }
      }
    }
  }

  // Fonction qui paramètre la modale si c'est pour un update/delete
  const EvalModalHandler = (event) => {
    const targetId = event.currentTarget.id;
    const ids = event.currentTarget.parentElement.id.split("_");

    if (report && notes) {
      const reportFiltred = report.filter(function (rep) {
        if (rep.id == ids[0]) {
          return rep.id == ids[0];
        }
      });
      const noteFiltred = notes.filter(function (note) {
        if (note.id == ids[1]) {
          return note.id == ids[1];
        }
      });
      if (modalStatus === false) {
        fixScrollPosition(modalStatus);
        setModalView(targetId);
        setModalStatus(true);
        setModalClick(true);
        setCurrentNotes(noteFiltred);
        setCurrentReport(reportFiltred);
      } else {
        fixScrollPosition(modalStatus);
        setModalStatus(false);
      }
    }
  };

  // Appel du composant de la modale en lui passant les props en fonction de l'ID du bouton qui déclenche l'appel
  let modalList;
  if (currentReport && currentNotes) {
    modalList = {
      evalModal: (
        <GenerateEvaluation
          modalFunction={EvalModalHandler}
          report={currentReport}
          notes={currentNotes}
          teacherInfos={props.teacherInfos}
          action="create"
          setNotesArray={setNotes}
        />
      ),
      updateModal: (
        <GenerateEvaluation
          modalFunction={EvalModalHandler}
          report={currentReport}
          notes={currentNotes}
          teacherInfos={props.teacherInfos}
          action="update"
          setNotesArray={setNotes}
        />
      ),
    };
  }

  useEffect(() => {
    // FETCH pour les notes en fonction du vcard du Teacher.

    const fetchNotes = async () => {
      try {
        const url = `${process.env.REACT_APP_API_HOST}/evaluation/get-note?teacher_id=${context.teacherVcard}&school_year=${context.teacherCurrentAccountYear
        }`;
        const response = await sendRequest(url, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setNotes(response);
      } catch (err) {
        console.error(err);
      }
    };
    fetchNotes();
  }, []);

  if (report) {
    return (
      <>
        {isModalClicked && currentReport && currentNotes && (
          <Modal
            show={modalStatus}
            onClickFunction={EvalModalHandler}
            props={currentReport}
            overlay={modalList[modalView]}
          />
        )}
        <div className={styles.listContainer}>
          {report &&
            notes &&
            report.map((rep) => (
              <ReportLine
                key={rep.id}
                repGroupId={rep.group_id}
                repId={rep.id}
                repCaption={rep.place_caption}
                groupsAndLinks={styles.groupsAndLinks}
                groupContainer={styles.groupContainer}
                repGroupName={rep.group_name}
                repSubjectName={rep.subject_Name}
                repSubject={rep.subject}
                btnStyles={styles.btnStyles}
                btn_active={styles.btn_active}
                btn_text={styles.btn_text}
                EvalModalHandler={EvalModalHandler}
                notes={notes}
                SearchIcon={SearchIcon}
                category={category}
              />
            ))}
        </div>
      </>
    );
  }
};

export default CreateEvaluation;
